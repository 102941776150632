import { object } from 'prop-types';
import { graphql } from 'gatsby';
import { removingWordFree } from '../../helpers';
import { Wrap } from '../../components/sections-wrap';
import * as styles from './index.module.scss';

function About({ data }) {
  return (
    <div className={styles.pageContainer}>
      <Wrap>
        <main className={styles.dataContainer}>
          <article className={styles.entry}>
            <div>
              <h1 className={styles.title}>
                About
              </h1>
            </div>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: removingWordFree(data?.wpPage?.content)}}
            />
          </article>
        </main>
      </Wrap>
    </div>
  );
}

export const query = graphql`
  {
    wpPage(uri: {eq: "/about/"}) {
      title
      content
      ...SeoPage
    }
  }
`;

About.propTypes = {
  data: object,
};

About.defaultProps = {
  data: {},
};

export default About;
export { Head } from '../../components/seo/seo';
